import React from "react";
import firebaseApp from "./Firebase";
import { Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import { Image, Toast, Card, Grid, List, Loading, Divider, AutoCenter } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import MateDiaryFooter from "./MateDiaryFooter"
import Helper from "./Helper"

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;


let count = 0;
let userId = "";


class UserMates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelection: {},
      profileById: {},
      visible: false,
      placement: "left",
      collapsed: false,
      receivedCount: -1
    };
  }



  removeMateByProfileId = (profileId) =>{
    const docData = {"user_id":userId, "profile_id":profileId};
    firebaseApp.removeMateByProfileId(docData);
  }

  chatWithProfileId = (profileId) =>{
    let mateIds = [userId, profileId];
    mateIds = mateIds.sort();
    window.location.href = "/chatmate/" + mateIds[0] + "-" + mateIds[1];
  }



  findAllMatesByUerId = (querySnapshot) => {
    let profileIds = [];
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        const profileData = doc.data();
        profileIds.push(profileData["profile_id"]);
        profileIds.push(profileData["user_id"]);
      });
      profileIds = Helper.getUniqueValues(profileIds);
      //remove all userId before pulling the profiles
      profileIds = profileIds.filter(item => item !== userId);
      const findAllProfilesQuery = firebaseApp.showProfilesByProfileId(profileIds);
      this.showAllProfiles(findAllProfilesQuery);
    });
  } 

  showAllProfiles = (querySnapshot) => {
    const profileById = {};
    let receivedCount = 0;
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        profileById[doc.id] = doc.data();
        profileById[doc.id]["id"] = doc.id;
        profileById[doc.id]["hobbies"] = Helper.hobbiesDisplay(profileById[doc.id]["hobbies"]);
        receivedCount++;
      });
      this.setState({ "profileById": profileById, "receivedCount": receivedCount });
    });
  }




  findInfoByProfileId = (profileId) => {
    window.location.href = "profile/" + profileId;
  }


  componentDidMount() {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    const userMatesQuery = firebaseApp.findAllMatesByUerId(userId);
    this.findAllMatesByUerId(userMatesQuery);
  }

  render() {

    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{ backgroundColor: "#B32400", paddingLeft: 0, position: 'fixed', zIndex: 1, width: '100%' }}>
            <SideMenu />
          </Header>

          <Content style={{ marginLeft:10, marginRight:10, marginTop: 65, backgroundColor: "#FFFFFF" }}>

            {Object.values(this.state.profileById).map((item, index) => (
              <div style={{ marginTop: 50 }} key={'div-' + index}>
                <Grid columns={4} gap={8} key={index}>
                  <Grid.Item>
                    <Image
                      src={item["image"]}
                      width={64}
                      height={64}
                      fit='cover'
                      style={{ borderRadius: 8 }}
                    />
                    <p><small>{item["g"]}, {item["age"]}</small></p>
                  </Grid.Item>
                  <Grid.Item span={3}>
                    <div><strong>Name:</strong> <span className="profileName">{item["name"]}</span></div>
                    {item["desc"] && (<div><strong>About:</strong> {item["desc"]}</div>)}
                    <div><strong>Hobbies:</strong> {item["hobbies"].join(", ")}</div>
                    <Space style={{marginTop:10}}>
                      <Button size='large' style={{ backgroundColor: '#CC33FF', border: '1px solid #CC33FF' }} color='primary' onClick={() => this.chatWithProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="far fa-comment-dots"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} color='primary' onClick={() => this.findInfoByProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-info-circle"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#0000FF', border: '1px solid #0000FF' }} color='primary' onClick={() => this.removeMateByProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-heart-broken"></i> </Button>
                    </Space>
                  </Grid.Item>
                </Grid>
              </div>
            ))}

            <div style={{fontSize:20, display: (this.state.receivedCount==0 ? "block" : "none") }}>
              <center>
                  <p>You don't have any mate yet.</p>
                  <p className="profileName">We don't tell you it is going to be easy. But we encourage because it will be worth.</p>
              </center>
            </div>

            <div style={{display: (this.state.receivedCount==-1 ? "block" : "none") }}>
              <AutoCenter>
                  <img width={500} src="loading-1.gif" />
              </AutoCenter>
            </div>


            <AutoCenter style={{ marginTop: 50 }}>
                <Space>
                  <Button style={{ backgroundColor: '#0000FF', border: '1px solid #0000FF' }} size='large' color='primary' onClick={() => this.sendHomePage()}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fa fa-home"></i> </Button>
                  <Button style={{ backgroundColor: '#CC33FF', border: '1px solid #0000FF' }} size='large' color='primary' onClick={() => this.viewProfile()}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-external-link-alt"></i> </Button>
                </Space>
              </AutoCenter>

          </Content>
          <MateDiaryFooter />
        </Layout>
      </div>
    );
  }
}

export default UserMates;