import React from "react";
import firebaseApp from "./Firebase";
import { Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import { Image, Toast, Card, Grid, Loading, Divider, AutoCenter } from "antd-mobile";
import MateDiaryFooter from "./MateDiaryFooter"
import Helper from "./Helper"

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;


let count = 0;
let userId = "";


class InterestReceived extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelection: {},
      profileById: {},
      visible: false,
      placement: "left",
      collapsed: false,
      receivedCount: -1
    };
  }


  sendHomePage = () =>
  {
    window.location.href = "/";
  }

  viewProfile = () =>
  {
    window.location.href = "/profile/" + userId;
  }


  removeRequestByProfileId = (profileId) => {
    let profileById = this.state.profileById;
    delete profileById[profileId];
    const requestId = profileId + "-" +userId;
    firebaseApp.removeRequestById(requestId);
    this.setState({ "profileById": profileById });
    Toast.show("Wink Request Denied");
  }



  findInterestReceived = (querySnapshot) => {
    let profileIds = [];
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        const profileData = doc.data();
        profileIds.push(profileData["user_id"]);
      });
      profileIds = Helper.getUniqueValues(profileIds);
      const findAllProfilesQuery = firebaseApp.showProfilesByProfileId(profileIds);
      this.showAllProfiles(findAllProfilesQuery);
    });

  }

  showAllProfiles = (querySnapshot) => {
    const profileById = {};
    let receivedCount = 0;
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        let profileProps = doc.data();
        profileProps["id"] = doc.id;
        profileProps["hobbies"] = Helper.hobbiesDisplay(profileProps["hobbies"]);
        profileProps["g"] = Helper.genderInterestDisplay(profileProps["g"]);
        profileById[doc.id] = profileProps;
        receivedCount++;
      });
      this.setState({ "profileById": profileById, "receivedCount": receivedCount });
    });
  }


  acceptRequestByProfileId = (profileId) => {
    firebaseApp.acceptRequestByProfileId({ "profile_id": profileId, "user_id": userId });
  }

  blockProfileById = (profileId) => {
    firebaseApp.blockProfileById({ "profile_id": profileId, "user_id": userId });
  }

  chatWithProfileId = (profileId) => {
    let mateIds = [userId, profileId];
    mateIds = mateIds.sort();
    window.location.href = "/chatmate/" + mateIds[0] + "-" + mateIds[1];
  }

  findInfoByProfileId = (profileId) => {
    window.location.href = "profile/" + profileId;
  }


  componentDidMount() {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    const findInterestReceived = firebaseApp.findInterestReceived(userId);
    this.findInterestReceived(findInterestReceived);
  }

  render() {

    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{ backgroundColor: "#B32400", paddingLeft: 0, position: 'fixed', zIndex: 1, width: '100%' }}>
            <SideMenu />
          </Header>

          <Content style={{ marginTop: 70, backgroundColor: "#FFFFFF", marginLeft:10, marginRight:10 }}>

            {Object.keys(this.state.profileById).map((item, index) => (
              <div style={{ marginTop: 50 }} key={'div-' + index}>
                <Grid columns={4} gap={8} key={index}>
                  <Grid.Item>
                    <Image
                      src={this.state.profileById[item]["image"]}
                      width={64}
                      height={64}
                      fit='cover'
                      style={{ borderRadius: 8 }}
                    />
                    <p><small>{this.state.profileById[item]["g"]}, {this.state.profileById[item]["age"]}</small></p>
                  </Grid.Item>
                  <Grid.Item span={3}>
                    <div><strong>Name:</strong> <span className="profileName">{this.state.profileById[item]["name"]}</span></div>
                    <div><strong>About:</strong> {this.state.profileById[item]["desc"]}</div>
                    <div><strong>Hobbies:</strong> {this.state.profileById[item]["hobbies"].join(", ")}</div>
                    <Space>
                      <Button size='large' style={{ backgroundColor: '#33CC33', border: '1px solid #33CC33' }} color='primary' onClick={() => this.acceptRequestByProfileId(item)}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-check-double"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#0000FF', border: '1px solid #0000FF' }} color='primary' onClick={() => this.removeRequestByProfileId(item)}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="far fa-trash-alt"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#CC33FF', border: '1px solid #CC33FF' }} color='primary' onClick={() => this.chatWithProfileId(item)}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="far fa-comment-dots"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} color='primary' onClick={() => this.findInfoByProfileId(item)}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-info-circle"></i> </Button>
                    </Space>
                  </Grid.Item>
                </Grid>
              </div>
            ))}

            <div style={{fontSize:20, display: (this.state.receivedCount==0 ? "block" : "none") }}>
              <center>
                  <p>You haven't received any interest yet.</p>
                  <img width={250} src="keep-hope-alive-prints.png" />
              </center>
            </div>

            <div style={{display: (this.state.receivedCount==-1 ? "block" : "none") }}>
              <AutoCenter>
                  <img width={500} src="loading-1.gif" />
              </AutoCenter>
            </div>


            <AutoCenter style={{ marginTop: 50 }}>
                <Space>
                  <Button style={{ backgroundColor: '#0000FF', border: '1px solid #0000FF' }} size='large' color='primary' onClick={() => this.sendHomePage()}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fa fa-home"></i> </Button>
                  <Button style={{ backgroundColor: '#CC33FF', border: '1px solid #0000FF' }} size='large' color='primary' onClick={() => this.viewProfile()}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-external-link-alt"></i> </Button>
                </Space>
              </AutoCenter>



          </Content>
          <MateDiaryFooter />
        </Layout>
      </div>
    );
  }

}

export default InterestReceived;