import React from "react";
import firebaseApp from "./Firebase";
import { Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import { Image, Toast, Grid, List, Loading, Divider, AutoCenter } from "antd-mobile";
import MateDiaryFooter from "./MateDiaryFooter"
import Helper from "./Helper"

const { SubMenu } = Menu;
const { Header, Footer, Sider, Content } = Layout;


let count = 0;
let userId = "";


class Bookmarks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelection: {},
      profileById: {},
      visible: false,
      placement: "left",
      receivedCount: -1
    };
  }


  processBookmarks = (querySnapshot) => {
    let profileIds = [];
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        const profileData = doc.data();
        profileIds.push(profileData["profile_id"]);
      });
      profileIds = [...new Set(profileIds)];
      profileIds = profileIds.filter(function (element) { return (element !== undefined) && (element !== "") });
      const findAllProfilesQuery = firebaseApp.showProfilesByProfileId(profileIds);
      this.showAllProfiles(findAllProfilesQuery);
    });

  }


  showAllProfiles = (querySnapshot) => {
    const profileById = {};
    let receivedCount = 0;
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        profileById[doc.id] = doc.data();
        profileById[doc.id]["id"] = doc.id;
        profileById[doc.id]["age"] = "NA";
        if (profileById[doc.id]["year"] != undefined) {
          profileById[doc.id]["age"] = (new Date().getFullYear() - profileById[doc.id]["year"]);
        }
        if (profileById[doc.id]["image"] == undefined) {
          profileById[doc.id]["image"] = "https://storage.googleapis.com/matediary-public-files/profiles/default-profile.jpeg";
        }
        profileById[doc.id]["hobbies"] = Helper.hobbiesDisplay(profileById[doc.id]["hobbies"]);
        profileById[doc.id]["g"] = Helper.genderInterestDisplay(profileById[doc.id]["g"]);
        receivedCount++;
      });
      console.log(profileById);
      this.setState({ "profileById": profileById, "receivedCount": receivedCount });
    });
  }


  sendRequestByProfileId = (profileId) => {
    firebaseApp.sendRequestByProfileId({ "profile_id": profileId, "user_id": userId });
    Toast.show("Wink Sent Successfully");
  }

  removeBookmarkById = (profileId) => {
    console.log(profileId);
    let profileById = this.state.profileById;
    delete profileById[profileId];
    let bookmarkId = userId + "-" + profileId;
    firebaseApp.removeBookmarkById(bookmarkId);
    this.setState({ "profileById": profileById });
    Toast.show("Removed from the Bookmark");
  }

  chatWithProfileId = (profileId) => {
    window.location.href = "chat/" + profileId;
  }

  findInfoByProfileId = (profileId) => {
    window.location.href = "profile/" + profileId;
  }

  componentDidMount() {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    const fileAllBooksmarks = firebaseApp.findAllBookmarks(userId);
    this.processBookmarks(fileAllBooksmarks);
  }

  render() {

    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{ backgroundColor: "#B32400", paddingLeft: 0, position: 'fixed', zIndex: 1, width: '100%' }}>
            <SideMenu />
          </Header>

          <Content style={{ marginTop:75, marginLeft:10, backgroundColor: "#FFFFFF" }}>

            {Object.values(this.state.profileById).map((item, index) => (
              <div style={{ marginTop: 50 }} key={'div-' + index}>
                <Grid columns={4} gap={8} key={index}>
                  <Grid.Item>
                    <Image
                      src={item["image"]}
                      width={80}
                      height={80}
                      fit='cover'
                      style={{ borderRadius: 8 }}
                    />
                    <p><small>{item["g"]}, {item["age"]}</small></p>
                  </Grid.Item>
                  <Grid.Item span={3}>
                    <div><strong>Name:</strong> <span className="profileName">{item["name"]}</span></div>
                    {item["about"] && (<div><strong>About:</strong> {item["about"]}</div>)}
                    <div><strong>Hobbies:</strong> {item["hobbies"].join(", ")}</div>
                    <Space>
                      <Button size='large' style={{ backgroundColor: '#33CC33', border: '1px solid #33CC33' }} color='primary' onClick={() => this.sendRequestByProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="far fa-smile-wink"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#0000FF', border: '1px solid #0000FF' }} color='primary' onClick={() => this.removeBookmarkById(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="far fa-trash-alt"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#CC33FF', border: '1px solid #CC33FF' }} color='primary' onClick={() => this.chatWithProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="far fa-comment-dots"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} color='primary' onClick={() => this.findInfoByProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-info-circle"></i> </Button>
                    </Space>
                  </Grid.Item>
                </Grid>
              </div>
            ))}

          <div style={{fontSize:20, display: (this.state.receivedCount==0 ? "block" : "none") }}>
              <center>
                  <p>You don't have any bookmark.</p>
              </center>
            </div>

            <div style={{display: (this.state.receivedCount==-1 ? "block" : "none") }}>
              <AutoCenter>
                  <img width={500} src="loading-1.gif" />
              </AutoCenter>
            </div>


            <div style={{ marginTop: 15 }}>
                <AutoCenter>
                    <Space style={{marginTop:30}}>
                        <Button style={{ backgroundColor: '#cccc00', border: '1px solid #cccc00' }} size='large' color='primary' size='large' color='primary' onClick={() => Helper.goHomePage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="fa fa-home"></i> </Button>
                        <Button style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} size='large' color='primary' size='large' color='primary' onClick={() => Helper.goInboxPage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="far fa-envelope"></i> </Button>
                        <Button style={{ backgroundColor: '#00ff00', border: '1px solid #00ff00' }} size='large' color='primary' size='large' color='primary' onClick={() => Helper.goSentPage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="far fa-paper-plane"></i> </Button>
                    </Space>
                </AutoCenter>
            </div>

            

          </Content>
          <MateDiaryFooter />
        </Layout>
      </div>
    );
  }

}

export default Bookmarks;