import React from "react";
import { Layout } from "antd";


const { Footer } = Layout;

function MateDiaryFooter(){

    return (
        <Footer className="footer-links" style={{backgroundColor:"#B32400", color:"#FFFFFF", marginTop:25}}>
            <center>
              <a href="/static/aboutus">About</a> | <a href="/static/privacy">Privacy</a> | <a href="/static/termsandcondition">Terms</a> | <a href="/contact">Contact</a>
            </center>
        </Footer>
    );

}

export default MateDiaryFooter;
