import React from "react";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import firebaseApp from "./Firebase";
import { Select } from "antd";

const { Option } = Select;
let currentTempUser = {};
let cartWinksApp = null;

/*
Configuration Ids
1-10    Self Gender
11-20   Gender Interests
*/

const hobbiesList = {"1":"Bicycling", "2":"Board Games", "3":"Calligraphy", "4":"Collecting", "5":"Cooking", "6":"Dancing", "7":"Eating Out", "8":"Fishing", "9":"Gardening", "10":"Genealogy", "11":"Golf", "12":"Housework", "13":"Music", "14":"Painting", "15":"Playing Cards", "16":"Podcasts", "17":"Reading", "18":"Religious Activities", "19":"Running", "20":"Shopping", "21":"Socializing", "22":"Team Sports", "23":"Television", "24":"Tennis", "25":"Travelling", "26":"Video Games", "27":"Volunteer Work", "28":"Walking", "29":"Watching Movies", "30":"Watching Sports", "31":"Writing", "32":"Yoga", "33":"Netflix"};
const genderList = {"1":"Female", "2":"Male", "3":"Other"};
const countryList = {"1":{"n":"UK","isd":44},
"2":{"n":"USA","isd":1},
"3":{"n":"Algeria","isd":213},
"4":{"n":"Andorra","isd":376},
"5":{"n":"Angola","isd":244},
"6":{"n":"Anguilla","isd":1264},
"7":{"n":"Antigua & Barbuda","isd":1268},
"8":{"n":"Argentina","isd":54},
"9":{"n":"Armenia","isd":374},
"10":{"n":"Aruba","isd":297},
"11":{"n":"Australia","isd":61},
"12":{"n":"Austria","isd":43},
"13":{"n":"Azerbaijan","isd":994},
"14":{"n":"Bahamas","isd":1242},
"15":{"n":"Bahrain","isd":973},
"16":{"n":"Bangladesh","isd":880},
"17":{"n":"Barbados","isd":1246},
"18":{"n":"Belarus","isd":375},
"19":{"n":"Belgium","isd":32},
"20":{"n":"Belize","isd":501},
"21":{"n":"Benin","isd":229},
"22":{"n":"Bermuda","isd":1441},
"23":{"n":"Bhutan","isd":975},
"24":{"n":"Bolivia","isd":591},
"25":{"n":"Bosnia Herzegovina","isd":387},
"26":{"n":"Botswana","isd":267},
"27":{"n":"Brazil","isd":55},
"28":{"n":"Brunei","isd":673},
"29":{"n":"Bulgaria","isd":359},
"30":{"n":"Burkina Faso","isd":226},
"31":{"n":"Burundi","isd":257},
"32":{"n":"Cambodia","isd":855},
"33":{"n":"Cameroon","isd":237},
"34":{"n":"Canada","isd":1},
"35":{"n":"Cape Verde Islands","isd":238},
"36":{"n":"Cayman Islands","isd":1345},
"37":{"n":"Central African Republic","isd":236},
"38":{"n":"Chile","isd":56},
"39":{"n":"China","isd":86},
"40":{"n":"Colombia","isd":57},
"41":{"n":"Comoros","isd":269},
"42":{"n":"Congo","isd":242},
"43":{"n":"Cook Islands","isd":682},
"44":{"n":"Costa Rica","isd":506},
"45":{"n":"Croatia","isd":385},
"46":{"n":"Cuba","isd":53},
"47":{"n":"Cyprus North","isd":90392},
"48":{"n":"Cyprus South","isd":357},
"49":{"n":"Czech Republic","isd":42},
"50":{"n":"Denmark","isd":45},
"51":{"n":"Djibouti","isd":253},
"52":{"n":"Dominica","isd":1809},
"53":{"n":"Dominican Republic","isd":1809},
"54":{"n":"Ecuador","isd":593},
"55":{"n":"Egypt","isd":20},
"56":{"n":"El Salvador","isd":503},
"57":{"n":"Equatorial Guinea","isd":240},
"58":{"n":"Eritrea","isd":291},
"59":{"n":"Estonia","isd":372},
"60":{"n":"Ethiopia","isd":251},
"61":{"n":"Falkland Islands","isd":500},
"62":{"n":"Faroe Islands","isd":298},
"63":{"n":"Fiji","isd":679},
"64":{"n":"Finland","isd":358},
"65":{"n":"France","isd":33},
"66":{"n":"French Guiana","isd":594},
"67":{"n":"French Polynesia","isd":689},
"68":{"n":"Gabon","isd":241},
"69":{"n":"Gambia","isd":220},
"70":{"n":"Georgia","isd":7880},
"71":{"n":"Germany","isd":49},
"72":{"n":"Ghana","isd":233},
"73":{"n":"Gibraltar","isd":350},
"74":{"n":"Greece","isd":30},
"75":{"n":"Greenland","isd":299},
"76":{"n":"Grenada","isd":1473},
"77":{"n":"Guadeloupe","isd":590},
"78":{"n":"Guam","isd":671},
"79":{"n":"Guatemala","isd":502},
"80":{"n":"Guinea","isd":224},
"81":{"n":"Guinea - Bissau","isd":245},
"82":{"n":"Guyana","isd":592},
"83":{"n":"Haiti","isd":509},
"84":{"n":"Honduras","isd":504},
"85":{"n":"Hong Kong","isd":852},
"86":{"n":"Hungary","isd":36},
"87":{"n":"Iceland","isd":354},
"88":{"n":"India","isd":91},
"89":{"n":"Indonesia","isd":62},
"90":{"n":"Iran","isd":98},
"91":{"n":"Iraq","isd":964},
"92":{"n":"Ireland","isd":353},
"93":{"n":"Israel","isd":972},
"94":{"n":"Italy","isd":39},
"95":{"n":"Jamaica","isd":1876},
"96":{"n":"Japan","isd":81},
"97":{"n":"Jordan","isd":962},
"98":{"n":"Kazakhstan","isd":7},
"99":{"n":"Kenya","isd":254},
"100":{"n":"Kiribati","isd":686},
"101":{"n":"Korea North","isd":850},
"102":{"n":"Korea South","isd":82},
"103":{"n":"Kuwait","isd":965},
"104":{"n":"Kyrgyzstan","isd":996},
"105":{"n":"Laos","isd":856},
"106":{"n":"Latvia","isd":371},
"107":{"n":"Lebanon","isd":961},
"108":{"n":"Lesotho","isd":266},
"109":{"n":"Liberia","isd":231},
"110":{"n":"Libya","isd":218},
"111":{"n":"Liechtenstein","isd":417},
"112":{"n":"Lithuania","isd":370},
"113":{"n":"Luxembourg","isd":352},
"114":{"n":"Macao","isd":853},
"115":{"n":"Macedonia","isd":389},
"116":{"n":"Madagascar","isd":261},
"117":{"n":"Malawi","isd":265},
"118":{"n":"Malaysia","isd":60},
"119":{"n":"Maldives","isd":960},
"120":{"n":"Mali","isd":223},
"121":{"n":"Malta","isd":356},
"122":{"n":"Marshall Islands","isd":692},
"123":{"n":"Martinique","isd":596},
"124":{"n":"Mauritania","isd":222},
"125":{"n":"Mayotte","isd":269},
"126":{"n":"Mexico","isd":52},
"127":{"n":"Micronesia","isd":691},
"128":{"n":"Moldova","isd":373},
"129":{"n":"Monaco","isd":377},
"130":{"n":"Mongolia","isd":976},
"131":{"n":"Montserrat","isd":1664},
"132":{"n":"Morocco","isd":212},
"133":{"n":"Mozambique","isd":258},
"134":{"n":"Myanmar","isd":95},
"135":{"n":"Namibia","isd":264},
"136":{"n":"Nauru","isd":674},
"137":{"n":"Nepal","isd":977},
"138":{"n":"Netherlands","isd":31},
"139":{"n":"New Caledonia","isd":687},
"140":{"n":"New Zealand","isd":64},
"141":{"n":"Nicaragua","isd":505},
"142":{"n":"Niger","isd":227},
"143":{"n":"Nigeria","isd":234},
"144":{"n":"Niue","isd":683},
"145":{"n":"Norfolk Islands","isd":672},
"146":{"n":"Northern Marianas","isd":670},
"147":{"n":"Norway","isd":47},
"148":{"n":"Oman","isd":968},
"149":{"n":"Palau","isd":680},
"150":{"n":"Panama","isd":507},
"151":{"n":"Papua New Guinea","isd":675},
"152":{"n":"Paraguay","isd":595},
"153":{"n":"Peru","isd":51},
"154":{"n":"Philippines","isd":63},
"155":{"n":"Poland","isd":48},
"156":{"n":"Portugal","isd":351},
"157":{"n":"Puerto Rico","isd":1787},
"158":{"n":"Qatar","isd":974},
"159":{"n":"Reunion","isd":262},
"160":{"n":"Romania","isd":40},
"161":{"n":"Russia","isd":7},
"162":{"n":"Rwanda","isd":250},
"163":{"n":"San Marino","isd":378},
"164":{"n":"Sao Tome & Principe","isd":239},
"165":{"n":"Saudi Arabia","isd":966},
"166":{"n":"Senegal","isd":221},
"167":{"n":"Serbia","isd":381},
"168":{"n":"Seychelles","isd":248},
"169":{"n":"Sierra Leone","isd":232},
"170":{"n":"Singapore","isd":65},
"171":{"n":"Slovak Republic","isd":421},
"172":{"n":"Slovenia","isd":386},
"173":{"n":"Solomon Islands","isd":677},
"174":{"n":"Somalia","isd":252},
"175":{"n":"South Africa","isd":27},
"176":{"n":"Spain","isd":34},
"177":{"n":"Sri Lanka","isd":94},
"178":{"n":"St. Helena","isd":290},
"179":{"n":"St. Kitts","isd":1869},
"180":{"n":"St. Lucia","isd":1758},
"181":{"n":"Sudan","isd":249},
"182":{"n":"Suriname","isd":597},
"183":{"n":"Swaziland","isd":268},
"184":{"n":"Sweden","isd":46},
"185":{"n":"Switzerland","isd":41},
"186":{"n":"Syria","isd":963},
"187":{"n":"Taiwan","isd":886},
"188":{"n":"Tajikstan","isd":7},
"189":{"n":"Thailand","isd":66},
"190":{"n":"Togo","isd":228},
"191":{"n":"Tonga","isd":676},
"192":{"n":"Trinidad & Tobago","isd":1868},
"193":{"n":"Tunisia","isd":216},
"194":{"n":"Turkey","isd":90},
"195":{"n":"Turkmenistan","isd":7},
"196":{"n":"Turkmenistan","isd":993},
"197":{"n":"Turks & Caicos Islands","isd":1649},
"198":{"n":"Tuvalu","isd":688},
"199":{"n":"Uganda","isd":256},
"200":{"n":"UK","isd":44},
"201":{"n":"Ukraine","isd":380},
"202":{"n":"United Arab Emirates","isd":971},
"203":{"n":"Uruguay","isd":598},
"204":{"n":"USA","isd":1},
"205":{"n":"Uzbekistan","isd":7},
"206":{"n":"Vanuatu","isd":678},
"207":{"n":"Vatican City","isd":379},
"208":{"n":"Venezuela","isd":58},
"209":{"n":"Vietnam","isd":84},
"210":{"n":"Virgin Islands - British","isd":1284},
"211":{"n":"Virgin Islands - US","isd":1340},
"212":{"n":"Wallis & Futuna","isd":681},
"213":{"n":"Yemen North","isd":969},
"214":{"n":"Yemen South","isd":967},
"215":{"n":"Zambia","isd":260},
"216":{"n":"Zimbabwe","isd":263}}

class Helper extends React.Component {
    
    constructor(props) {
        cartWinksApp = this;
        super(props);
    }

    static findCountryIsdOptions = () => {
        var isdOptions = [];
        for(var prop in countryList)
        {
            isdOptions.push(<Option key={prop}>{countryList[prop]["n"] + " (+" + countryList[prop]["isd"] + ")"}</Option>);

        }
        return isdOptions;
    }
    static countryIsdById(countryId)
    {
        return countryList[countryId]["isd"];
    }
    
    static findGenderOptions = () => {
        var genderOptions = [];
        for(var prop in genderList)
        {
            genderOptions.push({ "label": genderList[prop], "value": prop });
        }
        return genderOptions;
    }

    static findHobbiesOptions(){ 
        var hobbiesOptions = [];
        for(var prop in hobbiesList)
        {
            hobbiesOptions.push({ "label": hobbiesList[prop], "value": prop });
        }
        return hobbiesOptions;
     }

    static openLoginPage = () =>
    {
        const firebaseAuth = getAuth();
        onAuthStateChanged(firebaseAuth, (authorizedUser) => {
            if (authorizedUser) {
                console.log(authorizedUser.uid);
                this.manageUserEntity(authorizedUser);
            }
            else
            { 
                window.location.href = "/login";
            }
        });
    }

    static manageUserEntity = (currentUser) =>
    {
        console.log("manageUserEntity");
        if(currentUser.isAnonymous==true)
        {
            localStorage.setItem("currentUserAnonymous", "true");
            localStorage.setItem("currentAnonymousUserId", currentUser.uid);
            localStorage.setItem("currentUserId", currentUser.uid);
        }
        else
        {
            console.log("assignAnonymousUserWithAuthUser");
            localStorage.setItem("currentUserAnonymous", "false");
            let currentAnonymousUserId = localStorage.getItem("currentAnonymousUserId");
            localStorage.setItem("currentUserId", currentUser.uid);
            if(currentAnonymousUserId!=null)
            {
                firebaseApp.assignAnonymousUserWithAuthUser(currentAnonymousUserId, currentUser.uid);
            }
            localStorage.removeItem("currentAnonymousUserId");
        }
    }


    static getCurrentUser = () =>
    {
    const firebaseAuth = getAuth();
    onAuthStateChanged(firebaseAuth, (authorizedUser) => {
            if (authorizedUser) {
                console.log("User auth state change");
                cartWinksApp.setState( {"uid": authorizedUser.id} );
                currentTempUser["uid"] = authorizedUser.uid;
                localStorage.setItem("currentUserAnonymous", authorizedUser.isAnonymous);
                localStorage.setItem("currentUserId", currentTempUser["uid"]);
            }
            else {
                console.log("Unauth auth state change");
                if((localStorage.getItem("currentUserId")==null) || (localStorage.getItem("currentUserId")==undefined))
                {
                    currentTempUser["uid"] = "cwu"+new Date().getTime();
                    localStorage.setItem("currentUserId", currentTempUser["uid"]);
                }
                else
                {
                    currentTempUser["uid"] = localStorage.getItem("currentUserId");
                }
            }
        });
        console.log(currentTempUser);
        return currentTempUser;
    }



    static getUrlRoute = (urlPath) =>
    {
        urlPath = urlPath.split("/");
        console.log(urlPath);
        var urlRoutes = {};
        for(var i=0; i<urlPath.length-1; i++)
        {
            urlRoutes[urlPath[i]] = urlPath[i + 1];
        }
        return urlRoutes;
    }



    static getCartTotal = (productsById) =>
    {
        let cartTotal = 0;
        for(var item in productsById)
        {
            cartTotal = cartTotal + productsById[item]["total"];
        }
        return cartTotal;
    }

    static hobbiesDisplay = (hobbies) =>
    {
        if(hobbies==undefined) return [];
        hobbies = hobbies.filter(function (element) { return (hobbiesList[element] !== undefined) });
        hobbies.forEach(function(part, index) { hobbies[index] = hobbiesList[part]; });
        return hobbies;
    }

    static genderInterestDisplay = (gender) =>
    {
        if(gender==undefined) return "NA";
        if(genderList[gender]==undefined) return "NA";
        return genderList[gender];
    }

    static genderDisplay = (gender) =>
    {
        if(gender==undefined) return [];
        gender = gender.filter(function (element) { return (genderList[element] !== undefined) });
        gender.forEach(function(part, index) { gender[index] = genderList[part]; });
        return gender;
    }


    static sliceBySize = (inputArray, splitBy) =>
    {
        var resultArray = [];
        var newSlice = [];
        var beginAt = 0;
        var sliceAt = splitBy;

        do{
            newSlice = inputArray.slice(beginAt, sliceAt);
            resultArray.push(newSlice);
            beginAt = beginAt + splitBy;
            sliceAt = sliceAt + splitBy;
        }while(newSlice.length==splitBy);
        
        return resultArray;
    }



    static getCartTotalByStoreId = (productsById) =>
    {
        let storeByIds = {};
        for(var item in productsById)
        {
            const storeId = productsById[item]["store_id"];
            if(storeByIds[storeId]==undefined)
            {
                storeByIds[storeId] = {"total":0, "items":0};
            }
            storeByIds[storeId]["total"] += (productsById[item]["total"] * 1);
            storeByIds[storeId]["items"] += 1;
        }

        let storeByMetrics = [];
        for(var storeId in storeByIds)
        {
            storeByMetrics.push( {"store_id":storeId, "total":storeByIds[storeId]["total"], "items": storeByIds[storeId]["items"], "name":storeId} );
        }
        return storeByMetrics;
    }



    static getInteger = (givenInput) =>
    {
        if(givenInput > -1) return givenInput;
        return 0;
    }

    static resetCartItem = (givenProduct) =>
    {
        return Object.assign( givenProduct, { "total":0, "order_unit":0 } );
    }

    static getUniqueValues = (inputList) =>
    {
        inputList = [...new Set(inputList)];
        return inputList.filter(function( element ) { return (element !== undefined) && (element !== "") });
    }

    //Navigation methods
    static goHomePage = () => {
        window.location.href = "/";
    }

    static goInboxPage = () => {
        window.location.href = "/interest-received";
    }

    static goSentPage = () => {
        window.location.href = "/interest-sent";
    }
}

export default Helper;
