import React from "react";
import { Row, Col, Card, Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Meta } = Card;


class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelection: {},
      products: [],
      productsById: {},
      visible: false,
      collapsed: false,
    }
  }


  showDrawer = () => {
    this.setState({ visible: true });
  }

  openRequestSent = () => {
    this.toggleCollapsed();
    window.location.href = "/interest-sent";
  }

  openRequestReceived = () => {
    this.toggleCollapsed();
    window.location.href = "/interest-received";
  }

  openUserMates = () => {
    this.toggleCollapsed();
    window.location.href = "/mymates";
  }

  openApplicationPage = () => {
    window.location.href = "/static/application";
  }


  openBooksmarks = () => {
    this.toggleCollapsed();
    window.location.href = "/bookmarks";
  }

  openChatHistory = () => {
    this.toggleCollapsed();
    console.log("showing stores page");
    window.location.href = "/chat-history";
  }

  openPreference = () => {
    this.toggleCollapsed();
    console.log("showing stores page");
    window.location.href = "/preferences";
  }

  openProfile = () => {
    this.toggleCollapsed();
    console.log("showing stores page");
    window.location.href = "/myprofile";
  }

  openHomePage = () => {
    this.toggleCollapsed();
    console.log("showing home page");
    window.location.href = "/";
  }

  openPhoneAuth = () => {
    window.location.href = "/login";
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
      visible: true,
    });
    console.log(this.state.collapsed);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  componentDidMount() {

  }



  render() {

    return (
      <div>
        <Space>
          <Button onClick={this.toggleCollapsed} style={{ marginLeft: 10 }}> {this.state.collapsed ? <i className="fas fa-bars"></i> : <i className="fas fa-bars"></i>} </Button>
          <div className="appHeader"><i className="far fa-user"></i><i className="far fa-user"></i> MateDiary</div>
            <Drawer
              title={<span onClick={(e) => this.openHomePage()}><strong><i style={{fontSize:30, color:"#FF0000"}} className="fa fa-home" /> Home </strong></span>}
              placement={"left"}
              closable={false}
              onClose={this.onClose}
              visible={this.state.collapsed}
              key={"left"}
              zindex={1}
              style={{ marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0 }}>
              <Menu zindex={1000} style={{ fontSize:15, marginLeft: 10, marginRight: 0, paddingLeft: 0, paddingRight: 0 }}>
                <Menu.Item key="1" icon={<i style={{fontSize:20, color:"#FF0000"}} className="far fa-paper-plane"></i>} onClick={this.openRequestSent}>
                  Request Sent
                </Menu.Item>
                <Menu.Item key="MyOwnShops" icon={<i style={{fontSize:20, color:"#FF0000"}} className="far fa-envelope"></i>} onClick={this.openRequestReceived}>
                  Request Received
                </Menu.Item>
                <Menu.Item key="MyMates" onClick={this.openUserMates}>
                  <i style={{fontSize:20, color:"#FF0000"}} className="far fa-user"></i><i style={{fontSize:20, color:"#FF0000"}} className="far fa-user"></i> My Mates
                </Menu.Item>
                <Menu.Item key="MyBookmarks" icon={<i style={{fontSize:20, color:"#FF0000"}} className="far fa-bookmark"></i>} onClick={this.openBooksmarks}>
                  Bookmarks
                </Menu.Item>
                <Menu.Item key="MyChatHistory" icon={<i style={{fontSize:20, color:"#FF0000"}} className="far fa-comments"></i>} onClick={this.openChatHistory}>
                  Chat
                </Menu.Item>
                <Menu.Item key="phoneAuthMenu" icon={<i style={{fontSize:20, color:"#FF0000"}} className="fas fa-id-card"></i>} onClick={this.openProfile}>
                  Profile
                </Menu.Item>
                <Menu.Item key="application" icon={<i style={{fontSize:20, color:"#FF0000"}} className="far fa-window-maximize"></i>} onClick={this.openApplicationPage}>
                  App
                </Menu.Item>
              </Menu>
            </Drawer>
          </Space>
        </div>
    );
  }
}
export default SideMenu;