import React from "react";
import SideMenu from "./SideMenu";
import { Modal, Tabs, Select, Form, Card, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import firebaseApp from "./Firebase";
import { getAuth, signOut, RecaptchaVerifier, signInWithPhoneNumber, browserLocalPersistence, setPersistence, linkWithCredential, OAuthProvider, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import MateDiaryFooter from "./MateDiaryFooter"
import Helper from "./Helper";


const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Option } = Select;
const { Header, Content } = Layout;
let userPhoneNumber = null;
let userPhoneOTP = null;
let verificationId = null;
var phoneNumberPattern = /^\d{10,15}$/;
let userAddress = {};
let cartWinksApp = null;
let userCountryId = "";
let userIsdNumber = "91";
let isAnonymousUser = true;
let loginDiv = "block";
let logoutDiv = "none";
let userAddressesById = {}
let currentUserId = null;
let addressDefaults = { "name": "", "address_1": "", "address_2": "", "city": "", "state": "", "country": "", "email": "", "pincode": "" };
let confirmationResult = null;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
  initialvalues: { yourDesiredFormInputname:true }
}
const tailLayout = {
  wrapperCol: { offset: 8, span: 8 },
}

class Login extends React.Component {

  addressFormRef = React.createRef();


  constructor(props) {
    super(props);

    cartWinksApp = this;

    this.state = {
      phoneNumberInput: true,
      products: [],
      productsById: {},
      placement: "left",
      collapsed: false,
      loginDiv: 'block',
      captchaDiv: "block",
      logoutDiv: 'none',
      showOtpDiv: 'none',
      savedMessage: false,
      userAddress: {},
      otpReceivedDiv: 'none',
      validPhoneNumber: false,
      verifyUserOtp: false,
      userAddressesById: {},
      userAddressesDiv: "none",
      editUserAddressDiv: "none",
      modalAskSignin: false,
      firebaseMesage: "",
      firebaseMesageBoolean: "none"
    }
  }
  onSignInSubmit() {
    console.log("onSignInSubmit: onSignInSubmit");
    this.setState({"captchaDiv":"none"});
  }

  componentDidMount() {
    const firebaseAuth = getAuth();

    setPersistence(firebaseAuth, browserLocalPersistence);
    window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", { size: "normal", 'callback': (response) => { this.onSignInSubmit(); }, 'expired-callback': () => { console.log("captcha timeout. Please retry!"); } }, firebaseAuth);
    
    //this.pullUserAddress();

    onAuthStateChanged(firebaseAuth, (currentUser) => {
      console.log(currentUser);
      let userTempId = "cwu" + new Date().getTime();
      currentUserId = userTempId;
      if (currentUser) {
        userTempId = currentUser.uid;
        currentUserId = userTempId;
        userAddress["uid"] = userTempId;
        localStorage.setItem("currentUserId", userTempId);
        localStorage.setItem("currentUserAnonymous", currentUser.isAnonymous);
        isAnonymousUser = currentUser.isAnonymous;
        if (isAnonymousUser == true) {
          console.log("Authorized anonymous user");
          loginDiv = "block"
        }
        else {
          console.log("Authorized user");
          loginDiv = "none";
          logoutDiv = "block";
        }
        cartWinksApp.setState({ "uid": userTempId, "loginDiv": loginDiv, "userAddress": userAddress, "logoutDiv": logoutDiv });
        window.location.href = "/";
        return;
      }

      if ((localStorage.getItem("currentUserId") == null) || (localStorage.getItem("currentUserId") == undefined)) {
        localStorage.setItem("currentUserId", userTempId);
      }
      else {
        userTempId = localStorage.getItem("currentUserId");
      }

      userAddress["uid"] = userTempId;
      const firebaseAuth = getAuth();
      setPersistence(firebaseAuth, browserLocalPersistence);
      //window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", { size: "normal", 'callback': (response) => { this.onSignInSubmit(); }, 'expired-callback': () => { console.log("captcha timeout. Please retry!"); } }, firebaseAuth);
      cartWinksApp.setState({ "uid": userTempId, "loginDiv": loginDiv, "userAddress": userAddress, "logoutDiv": logoutDiv });
      console.log("Unauthorized user");
    });

    console.log(userAddress);
  }

  getUserPhoneNumber = (e) => {
    userPhoneNumber = e.target.value;
    userPhoneNumber = userPhoneNumber.replace(/ /g, "");
    console.log(userPhoneNumber);
  }



  getUserPhoneOTP = (e) => {
    userPhoneOTP = e.target.value;
    console.log(userPhoneOTP);
    console.log(userPhoneOTP.length);
    if (userPhoneOTP.length > 5) {
      this.verifyWithOTP();
    }
  }



  userAddressInput = (e, fieldName) => {
    userAddress[fieldName] = e.target.value;
    console.log(userAddress);
    this.setState({ "savedMessage": false })
  }



  displayUserAddress = (queryPromise) => {
    userAddressesById = {};
    queryPromise.then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        userAddressesById[doc.id] = doc.data();
        userAddressesById[doc.id]["id"] = doc.id;
      });
      this.setState({ "userAddressesDiv": "block", "userAddressesById": userAddressesById });
    });
    console.log(userAddressesById);
  };




  pullUserAddress = () => {
    if ((localStorage.getItem("currentUserId") == null) || (localStorage.getItem("currentUserId") == undefined)) {
      return;
    }
    const currentUserId = localStorage.getItem("currentUserId");
    console.log(currentUserId);
    var queryPromise = firebaseApp.findUserAddress(currentUserId);
    this.displayUserAddress(queryPromise);
  }



  editUserAddressById = (addressId) => {
    if (isAnonymousUser) {
      this.setState({ "modalAskSignin": true });
      return;
    }
    if (userAddressesById[addressId] == undefined) {
      userAddress = addressDefaults;
    }
    else {
      userAddress = userAddressesById[addressId];
    }

    console.log(userAddress);

    this.addressFormRef.current.setFieldsValue(userAddress);

    this.setState({ "userAddress": userAddress, "editUserAddressDiv": "block" });
  }

  deleteUserAddressById = (addressId) => {
    delete userAddressesById[addressId];
    firebaseApp.deleteUserAddressById(addressId);
    this.setState({ "userAddressesDiv": "block", "userAddressesById": userAddressesById });

  }



  handleOk = () => {
    this.setState({ "modalAskSignin": false });
  }

  handleCancel = () => {
    this.setState({ "modalAskSignin": false });
  }

  saveUserDetails = () => {
    localStorage.setItem("cartwinksaddress", JSON.stringify(userAddress));
    currentUserId = localStorage.getItem("currentUserId")
    if (userAddress["doc_id"] == undefined) {
      userAddress["doc_id"] = currentUserId + "_" + new Date().getTime();
      userAddress["created_at"] = firebaseApp.findServerTime();
    }
    else {
      userAddress["updated_at"] = firebaseApp.findServerTime();
    }
    userAddress["user_id"] = currentUserId;
    userAddress["active"] = 1;
    console.log(userAddress);
    localStorage.setItem("cartwinksaddress", JSON.stringify(userAddress));
    firebaseApp.saveUserAddress( userAddress["doc_id"], userAddress );
    this.setState({ "savedMessage": true });
    setTimeout(function(){ window.location.href = "/login"; }, 2000);
  }




  getUserAuth = () => {
    const firebaseAuth = getAuth();
    setPersistence(firebaseAuth, browserLocalPersistence);
    onAuthStateChanged(firebaseAuth, (currentUser) => {
      if (currentUser) {
        const uid = currentUser.uid;
        isAnonymousUser = currentUser.isAnonymous;
        console.log("User is signed in");
      } else {
        console.log("User is signed out");
      }
    });
  }


  otpRequestToServer() {
    console.log("otpRequestToServer....");
    if (!phoneNumberPattern.test(userPhoneNumber)) {
      console.log("Invalid phone number....");
      return;
    }
    this.setState({ "validPhoneNumber": true});
    //firebase.auth().settings.appVerificationDisabledForTesting = true;
    const appVerifier = window.recaptchaVerifier;
    const userPhoneWithIsd = "+" + userIsdNumber + userPhoneNumber;
    console.log(userPhoneWithIsd);
    loginDiv = "none";
    const firebaseAuth = getAuth();
    setPersistence(firebaseAuth, browserLocalPersistence);
    signInWithPhoneNumber(firebaseAuth, userPhoneWithIsd, appVerifier)
      .then(confirmResult => {
        console.log(confirmResult);
        confirmationResult = confirmResult
        verificationId = confirmResult.verificationId;
        this.setState({ "showOtpDiv": "block", "otpReceivedDiv": "block", "loginDiv": loginDiv, "validPhoneNumber": true });
      })
      .catch(error => {
        console.log("Error while sighin with phone number");
        this.setState({ "firebaseMesage": error.message, "firebaseMesageBoolean": "block" });
        console.log(error);
        localStorage.removeItem("currentUserId");
      });
  }

  verifyWithOTP = () => {
    console.log("Validating the OTP....");
    const firebaseAuth = getAuth();
    setPersistence(firebaseAuth, browserLocalPersistence);
    confirmationResult.confirm(userPhoneOTP).then((result) => {
      const user = result.user;
      console.log(user);
      loginDiv = "none";
      logoutDiv = "block";
      console.log("Anonymous account successfully upgraded", user);
      localStorage.setItem("currentUserId", user.uid);
      let userProfileProps = {"active":1, "cid":userCountryId, "user_id":user.uid};
      firebaseApp.addNewUserProfile(user.uid, userProfileProps);
      this.setState({ "verifyUserOtp": true, "otpReceivedDiv": "none" });

      console.log("verifyWithOTP....");
      firebaseApp.assignAnonymousUserWithAuthUser(localStorage.getItem("currentAnonymousUserId"), user.uid);
      localStorage.removeItem("currentAnonymousUserId");
      
      const credential = OAuthProvider.credentialFromResult(result);
      linkWithCredential(user.currentUser, credential)
      .then((usercred) => {
        const linkedUser = usercred.user;
        localStorage.setItem("currentUserId", linkedUser["uid"]);
        console.log("Anonymous account linked upgraded", linkedUser);
        window.location.href = "/";
        setTimeout(function(){ window.location.href = "/profile"; }, 2000);
      }).catch((error) => {
        console.log("Error upgrading anonymous account", error);
      });
      
    }).catch((error) => {
      console.log("Error upgrading anonymous account", error);
    });

  }


  userIsdCodeChanged = (selectedCountryId) => {
    userCountryId = selectedCountryId;
    userIsdNumber = Helper.countryIsdById(selectedCountryId);
    this.setState({ "phoneNumberInput": false });
    console.log(selectedCountryId);
  }


  handleClick = e => {
    e.preventDefault();
    this.props.form.setFieldsValue({ name:"Saravan" });
  };

  tabsClicked = (key) => {
    if (key == "user-address") {
      console.log(key);
      this.pullUserAddress(key);
    }
    this.setState({ "editUserAddressDiv": "none" });
  };

  render() {

    return (
      <div>
        <Layout style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/background2.jpg'})`, padding: '0px 0px 0px 0px', minHeight: "100vh" }}>
          <Header style={{ backgroundColor:"#b32400", paddingLeft: 0 }}>
            <SideMenu />
          </Header>

          <Content style={{ display: this.state.loginDiv, marginTop: 20, padding: '0px 0px 0px 0px', width: '100%' }}>
            <center>
              <Card style={{ width: "300px" }}>
                <div>
                  <Select placeholder="Select Country/ISD" style={{ width: "250px" }} size='large' showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                    onSelect={(e) => this.userIsdCodeChanged(e)} onChange={(e) => this.userIsdCodeChanged(e)}>{Helper.findCountryIsdOptions()}
                  </Select>
                </div>

                <div style={{ marginTop: 10 }}>
                  <Input size='large' disabled={this.state.phoneNumberInput} onChange={(e) => this.getUserPhoneNumber(e)} />
                </div>

                <div style={{ marginTop: 10 }}>
                  <Button style={{ backgroundColor: '#FF6347', color: '#FF6347' }} size='large' loading={this.state.validPhoneNumber} onClick={(e) => this.otpRequestToServer(e, 1)}><Text strong style={{color:"#FFFFFF"}}>REQUEST OTP</Text></Button>
                </div>
              </Card>
            </center>
          </Content>

          <Content style={{ margin: '20px 0px 0px 0px', padding: '0px 0px 0px 0px', width: '100%', display: this.state.otpReceivedDiv }}>
            <center>
              <Card style={{ margin: '20px 0px 0px 0px', padding: '0px 0px 0px 0px', width: 200 }}>
                <div>
                  <Input size='large' placeholder="Enter OTP" onChange={(e) => this.getUserPhoneOTP(e)} />
                </div>
                <div style={{ marginTop: 10 }}>
                  <Button loading={this.state.verifyUserOtp} style={{ backgroundColor: '#FF6347', color: '#FF6347' }} size='large' onClick={(e) => this.verifyWithOTP(e, 1)}><Text strong style={{ color: '#FFFFFF' }}>VERIFY OTP</Text></Button>
                </div>
              </Card>
            </center>
          </Content>

          <Content key="google-captcha" style={{ display: this.state.captchaDiv, marginTop: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', width: '100%' }}>
            <center>
              <div id="recaptcha-container"></div>
            </center>
          </Content>

          <Content style={{ display: this.state.logoutDiv }}>
            <Card>
              <center>
                <p><img src="welcome.png"/></p>
                <p>Click here to explore profiles<br/>
                <Button style={{ backgroundColor: '#2F4F4F', border: '1px solid #2F4F4F' }} onClick={(e) => window.location.href="/"} ><Text style={{ color: '#FFFFFF' }} strong>Profiles</Text></Button>
                </p>
                <p>We recommend you to improve your profile for better matching.<br/>
                <Button style={{ backgroundColor: '#9900FF', border: '1px solid #9900FF' }} onClick={(e) => window.location.href="/"} ><Text style={{ color: '#FFFFFF' }} strong>My Profile</Text></Button>
                </p>
              </center>
            </Card>
          </Content>

          {/*
          <Tabs defaultActiveKey="2" onChange={(e) => this.tabsClicked(e)} style={{marginTop: 50, marginLeft: 10, marginRight: 10, display:((this.state.loginDiv=="block") ? 'none' : 'block') }}>

            <TabPane tab="Address" key="user-address">
              <Content style={{ backgroundColor: "#FFFFFF" }}>
                {Object.values(this.state.userAddressesById).map((address, i) => (
                  <div key={"address-" + i}>
                      <div style={{marginLeft:0, marginTop:10}}>{address.name}, {address.address_1}, {address.address_2}, <br />{address.city}, {address.pincode}, <br />{address.state}</div>
                      <div style={{marginLeft:0, marginTop:10, marginBottom:10}}>
                        <Space size={10}><Button size='small' onClick={(e) => this.editUserAddressById(address.id)}><i className="fas fa-edit"></i>&#160;Edit</Button>
                        <Button danger size='small' onClick={(e) => this.deleteUserAddressById(address.id)}><i className="far fa-trash-alt"></i>&#160;Remove</Button>
                        </Space>
                      </div>
                    <hr />
                  </div>
                ))}
                <div>
                  <center>
                    <div><Button type="primary" size='large' onClick={(e) => this.editUserAddressById(1)}><i className="fas fa-plus"></i>&#x2003;New Address</Button></div>
                  </center>
                </div>
              </Content>
            </TabPane>

            <TabPane tab="Vouchers" key="vouchers">
              <Content style={{ backgroundColor: "#FFFFFF" }}>
                <p>Sorry. You have no vouchers now.</p>
              </Content>
            </TabPane>

          </Tabs>
          */}

          <Content style={{ display: this.state.editUserAddressDiv, marginLeft: 10, marginRight: 10 }}>
            <Card>
              <Form {...layout} name="address_form" onSubmit={this.handleClick} ref={this.addressFormRef}>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'name')} />
                </Form.Item>
                <Form.Item name="address_1" label="Address 1" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'address_1')} />
                </Form.Item>
                <Form.Item name="address_2" label="Address 2">
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'address_2')} />
                </Form.Item>
                <Form.Item name="city" label="City" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'city')} />
                </Form.Item>
                <Form.Item name="pincode" label="Pincode" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'pincode')} />
                </Form.Item>
                <Form.Item name="state" label="State" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'state')} />
                </Form.Item>
                <Form.Item name="country" label="Country" rules={[{ required: true }]}>
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'country')} />
                </Form.Item>
                <Form.Item name="email" label="Email">
                  <Input size='large' onChange={(e) => this.userAddressInput(e, 'email')} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  {this.state.savedMessage ? (
                    <Button style={{ backgroundColor: '#2F4F4F', border: '1px solid #2F4F4F', color: '#FFFFFF' }} size='large' type="dashed"><Text style={{ color: '#FFFFFF' }} strong><i className="far fa-check-circle"></i>&#x2003;Saved&#x2003;</Text></Button>
                  ) : (
                    <Button style={{ backgroundColor: '#2F4F4F', border: '1px solid #2F4F4F', color: '#FFFFFF' }} size='large' type="primary" onClick={(e) => this.saveUserDetails(e, 1)}><Text style={{ color: '#FFFFFF' }} strong><i className="fas fa-save"></i>&#x2003;Save&#x2003;</Text></Button>
                  )
                  }
                </Form.Item>
              </Form>
            </Card>
          </Content>

          <Modal title="Login is required" visible={this.state.modalAskSignin} onOk={this.handleOk} onCancel={this.handleCancel}>
            <p>Please login using the OTP before adding your address.</p>
          </Modal>

          <div style={{display:this.state.firebaseMesageBoolean, fontSize:16, backgroundColor:"#DCDCDC", color:"#CC0000", margin:"20px 20px 20px 20px", padding:"20px 20px 0px 20px"}}>
            <center>
            <p><small>{this.state.firebaseMesage}</small> <br/> <strong>Unable to login now. Please try after sometime.</strong></p>
            </center>
          </div>
          
          <div style={{fontSize:20, backgroundColor:"#DCDCDC", margin:"20px 20px 20px 20px", padding:"20px 20px 0px 20px"}}>
            <center>
            <p><strong>The first full featured <br/> social networking app. <br/> No payment is required. It is our complimentary. <i className="fas fa-gift"></i></strong></p>
            </center>
          </div>

          <MateDiaryFooter/>
        </Layout>
      </div>
    )
  }
}
export default Login
