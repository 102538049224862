import React from "react";
import firebaseApp from "./Firebase";
import { Layout, Button, Space, Select, Input } from "antd";
import SideMenu from "./SideMenu";
import { Image, Toast, Card, List, AutoCenter }  from "antd-mobile";
import MateDiaryFooter from "./MateDiaryFooter"
import Helper from "./Helper"
import localStore from "store2"

const { Header, Content } = Layout;
const { Option } = Select;


let userId = "";
let globalSearchProps = [];
let globalSearchCityProps = [];
//let countryNames = [];
let cityNames = [];



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelection: {},
      profileById: {},
      visible: false,
      placement: "left",
      collapsed: false,
      showFilter: false,
      profileCount: -1
    };
  }

  openProfile = () =>
  {
    window.location.href = "/myprofile";
  }


  showSelectedProfiles = (querySnapshot) => {
    const profileById = {};
    let profileCount = 0;
    querySnapshot.then((querySnapshot) => {
      querySnapshot.forEach(function (doc) {
        let profileProps = doc.data();
        profileProps["age"] = "NA";
        profileProps["id"] = doc.id;
        if (profileProps["image"] === undefined) {
          profileProps["image"] = "https://storage.googleapis.com/matediary-public-files/profiles/default-profile.jpeg";
        }
        if (profileProps["year"] !== undefined) {
          profileProps["age"] = (new Date().getFullYear() - profileProps["year"]);
        }
        profileProps["hobbies"] = Helper.hobbiesDisplay(profileProps["hobbies"]);
        profileProps["gender"] = Helper.genderDisplay(profileProps["gender"]);
        profileById[doc.id] = profileProps;
        if(profileProps["city"] !== undefined)
        {
          cityNames.push(profileProps["city"].trim());
        }
        profileCount++;
      });

      cityNames = Helper.getUniqueValues(cityNames);
      
      delete profileById[userId];
      this.setState({ "profileById": profileById, "profileCount": profileCount});
      localStore.set("allProfiles", profileById);
      localStore.set("cityNames", cityNames);
    });
  }


  sendRequestByProfileId = (profileId) => {
    firebaseApp.sendRequestByProfileId({ "profile_id": profileId, "user_id": userId });
    Toast.show("Wink Sent Successfully");
  }

  bookmarkByProfileId = (profileId) => {
    firebaseApp.bookmarkByProfileId({ "profile_id": profileId, "user_id": userId });
    Toast.show("Added to the Bookmark");
  }

  chatWithProfileId = (profileId) => {
    //let mateIds = [userId, profileId];
    //mateIds = mateIds.sort();
    window.location.href = "/chat/" + profileId;
  }

  findInfoByProfileId = (profileId) => {
    window.location.href = "profile/" + profileId;
  }

  handSearchFilters = (cities) =>
  {
    globalSearchCityProps = cities;
    console.log(globalSearchCityProps);
  }

  searchByFilters = () =>
  {
    globalSearchProps = [];
    globalSearchCityProps.forEach(eachCity => {
      globalSearchProps.push("CITY"+eachCity.toLowerCase())
    })
    const findAllProfilesQuery = firebaseApp.findProfilesBySearch(globalSearchProps, 100);
    this.showSelectedProfiles(findAllProfilesQuery);
  
  }

  componentDidMount() {
    Helper.openLoginPage();
    userId = localStorage.getItem("currentUserId");
    const allProfiles = localStore.get("allProfiles");
    const localCityNames = localStore.get("cityNames");
    if (localCityNames !== null && Array.isArray(localCityNames)) {
      cityNames = localStore.get("cityNames");
    }

    if (allProfiles !== null && typeof(allProfiles)==="object") {
      this.setState({ "profileById": allProfiles });
    }

    let userProfileProps = localStore.get("mateDiaryUserProfile");

    if (userProfileProps === null) {
      Toast.show("Please update your profile");
      const findAllProfilesQuery = firebaseApp.findAllProfiles(100);
      this.showSelectedProfiles(findAllProfilesQuery);
      return;
    }

    let searchProps = [];

    if ((userProfileProps["gi"] === undefined) || (userProfileProps["gi"].length === 0)) userProfileProps["gi"] = [1, 2, 3];
    userProfileProps["gi"].forEach(function (part, index) { searchProps.push("G" + part) });

    if ((userProfileProps["zip"] !== undefined) && (userProfileProps["zip"].length !== 0)) {
      userProfileProps["zip"].forEach(function (part, index) { searchProps.push("ZIP" + part) });
    }
    else {
      searchProps.push("CID" + userProfileProps["cid"]);
    }

    console.log(searchProps);
    const findAllProfilesQuery = firebaseApp.findProfilesByPreference(searchProps, 100);
    this.showSelectedProfiles(findAllProfilesQuery);
  }

  render() {

    return (
      <div>
        <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
          <Header style={{ backgroundColor: "#B32400", paddingLeft: 0, position: 'fixed', zIndex: 1, width: '100%' }}>
            <SideMenu />
          </Header>


          <Content style={{ marginTop: 50, backgroundColor: "#FFFFFF" }}>

            <List>
              {Object.values(this.state.profileById).sort(() => Math.random() - 0.5).map((item, index) => (
                <Card key={index} style={{ borderRadius: '16px', border: "1px solid teal", marginTop: 20 }}>
                  <div><Image src={item["image"]} width="100%" fit='fill' /></div>
                  <div><strong>Name:</strong> <span className="profileName">{item["name"]}</span> <small className="profileAge">(<strong>Age:</strong> {item["age"]})</small></div>
                  {item["desc"] && (<div><strong>About:</strong> {item["desc"]}</div>)}
                  {item["city"] && (<div><strong>City:</strong> {item["city"]}</div>)}
                  {item["zip"] && (<div><strong>Location:</strong> {item["zip"].join(", ")}</div>)}
                  {(item["hobbies"].length !== 0) && (<div><strong>Hobbies:</strong> {item["hobbies"].join(", ")}</div>)}
                  <AutoCenter style={{ marginTop: 10 }}>
                    <Space size={15}>
                      <Button size='large' style={{ backgroundColor: '#33CC33', border: '1px solid #33CC33' }} color='primary' onClick={() => this.sendRequestByProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="far fa-kiss-wink-heart"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#0000FF', border: '1px solid #0000FF' }} color='primary' onClick={() => this.bookmarkByProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="far fa-bookmark"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#CC33FF', border: '1px solid #CC33FF' }} color='primary' onClick={() => this.chatWithProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="far fa-comment-dots"></i> </Button>
                      <Button size='large' style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} color='primary' onClick={() => this.findInfoByProfileId(item["id"])}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-info-circle"></i> </Button>
                    </Space>
                  </AutoCenter>
                </Card>
              ))}
            </List>


            <div style={{marginTop:20, display:(!this.state.showFilter ? "block" : "none")}}>
            <center>
              <Space>
                <Button onClick={(e) => { window.location.href = "/"; }}><i className="fas fa-sync-alt"></i></Button>
                <Button onClick={(e) => { this.setState({"showFilter":true}); globalSearchProps = []; }}><i className="fas fa-sliders-h"></i></Button>
              </Space>
              </center>
            </div>

            <div style={{marginTop:20, display:(this.state.showFilter ? "block" : "none")}}>
              <center>
                <Input.Group compact>
                  <Select mode="tags" style={{ width: 'calc(100% - 100px)' }} placeholder="Type city name" onChange={(e) => this.handSearchFilters(e)}>
                    {cityNames.map(item => (
                      <Option key={item}>{item}</Option>
                    ))}
                  </Select>
                  <Button onClick={(e) => this.searchByFilters()} type="primary"><i className="fas fa-search"></i></Button>
                </Input.Group>
              </center>
            </div>


          <div style={{marginTop:20, display: (this.state.profileCount==0 ? "block" : "none") }}>
              <center>
                  <p className="profileName">Sorry, we don't find a better match. But, you can improve your profile to find the best matches.</p>
                  <p><Button size='large' style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} color='primary' onClick={() => this.openProfile()}> <i style={{ fontSize: 25, color: "#FFFFFF" }} className="fas fa-user-circle"></i> </Button></p>
              </center>
            </div>

            <div style={{display: (this.state.profileCount==-1 ? "block" : "none") }}>
              <AutoCenter>
                  <img width={500} src="loading-1.gif" />
              </AutoCenter>
            </div>

          </Content>
          <MateDiaryFooter />
        </Layout>
      </div>
    );
  }

}

export default App;
