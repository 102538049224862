import { useState, useEffect, React } from "react";
import firebaseApp from "./Firebase";
import { Table, Typography, InputNumber, Input, Layout, Menu, Drawer, Button, Radio, Space } from "antd";
import SideMenu from "./SideMenu";
import { Image, Grid, Card, List, TextArea, AutoCenter } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import MateDiaryFooter from "./MateDiaryFooter"
import Helper from "./Helper"
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Moment from 'react-moment';
import { v4 as uuidv4 } from 'uuid';

const { SubMenu } = Menu;
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;

let clickAble = true;
let userId = "";
let emailType = 0;
let userMessageProps = {};
let coindedCorresId = "";
let corresHistoryById = {};
const chatStyle = {
    "sent": { borderRadius: 5, marginTop: 5, padding: "5px 5px 0px 5px", backgroundColor: "#E6fff2", "textAlign": "right" },
    "received": { borderRadius: 5, marginTop: 5, padding: "5px 5px 0px 5px", border: "1px solid yello", backgroundColor: "#FFFFFF", "textAlign": "left" }
}


function ContactPage() {
    let { corresId } = useParams();
    const pageNavigate = useNavigate();
    const {state} = useLocation();
    if(state!=undefined){
        console.log(state);
    }
    let [corresHistory, setCorresHistory] = useState([]);


    useEffect(() => {

        Helper.openLoginPage();
        userId = localStorage.getItem("currentUserId");

        if (corresId !== undefined) {
            clickAble = false;
            console.log("existing email", userId);
            coindedCorresId = corresId;
            const findChatHistoryQuery = firebaseApp.findEmailsByCorresId(corresId);
            displayEmailHistory(findChatHistoryQuery);
            emailType = 1;
        }

        if (corresId == undefined) {
            clickAble = true;
            coindedCorresId = uuidv4();
            emailType = 0;
            console.log("new email", userId);
            const findChatHistoryQuery = firebaseApp.findEmailsByUserId(userId);
            displayEmailHistory(findChatHistoryQuery);
        }

    }, []);


    function displayEmailHistory(queryPromise) {
        queryPromise.then((querySnapshot) => {
            let chatHistory = [];
            querySnapshot.forEach((doc) => {
                let chatProps = doc.data();
                chatProps["id"] = doc.id;
                chatProps["style"] = "received";
                if (chatProps["user_id"] == userId) chatProps["style"] = "sent";
                chatProps["created_at"] = chatProps["created_at"].toDate();
                corresHistoryById[doc.id] = chatProps;
                chatHistory.push(chatProps);
            });
            setCorresHistory(chatHistory);
        });
    }


    function setDescriptionValue(fieldName, e){
        console.log(fieldName, e);
        userMessageProps[fieldName] = e;
      }

      
    function sendBackPage() {
        pageNavigate(-1);
    }
    function sendHomePage() {
        window.location.href = "/"
    }

    function openChildEmails(rowId)
    {
        if(clickAble==false) return;
        let emailProps = corresHistoryById[rowId];
        //pageNavigate('/contact/email/'+emailProps["corres_id"], { state: emailProps });
        window.location.href = "/contact/"+emailProps["corres_id"];
        
    }

    function setFieldValue(fieldName, e) {
        console.log(fieldName, e.target.value);
        userMessageProps[fieldName] = e.target.value;
    }

    function sendEmailMessage() {
        if (userMessageProps["desc"] == undefined) { return; }
        if (userMessageProps["desc"].length == 0) { return; }
        userMessageProps["user_id"] = userId;
        userMessageProps["corres_id"] = coindedCorresId;
        userMessageProps["type"] = emailType;
        firebaseApp.sendEmailMessage(userMessageProps);
        window.location.href = window.location.href;
    }

    return (
        <div>
            <Layout style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
                <Header style={{ backgroundColor: "#B32400", paddingLeft: 0, position: 'fixed', zIndex: 1, width: '100%' }}>
                    <SideMenu />
                </Header>

                <Content style={{ marginTop: 80, marginLeft: 10, marginRight: 10, backgroundColor: "#FFFFFF" }}>

                    <div style={{ backgroundColor: "#D3D3D3", padding: "10px 10px 10px 10px" }}>
                        <List>
                            {corresHistory.map((item, i) => (
                                <List.Item key={item["id"]} title={item["subject"]} clickable={clickAble} onClick={(e) => openChildEmails(item["id"])} >
                                    <div className="longPara">{item["desc"]} <br/> <small><i className="far fa-clock"></i> <Moment>{item["created_at"]}</Moment></small></div>
                                </List.Item>
                            ))}
                        </List>
                    </div>
                    

                    <Card key="subject" style={{marginTop:25, border: "1px solid gray"}}>
                        <Input
                            placeholder='Subject'
                            onChange={v => setFieldValue("subject", v)}
                        />
                    </Card>

                    <Card key="message" style={{border: "1px solid gray", marginTop:10}}>
                        <TextArea
                            style={{ marginTop: 10, padding: "10 10 10 10" }}
                            placeholder='Write your message'
                            onChange={v => setDescriptionValue("desc", v)}
                            maxLength={500}
                            rows={5}
                            autoSize={true}
                        />
                    </Card>

                    <div style={{ marginTop: 5, textAlign: "right" }}>
                        <Button style={{ color: "#FFFFFF", backgroundColor: '#FFA500', border: '1px solid #FFA500' }} size='large' color='primary' onClick={() => sendEmailMessage()}> <i style={{ fontSize: 15 }} className="far fa-paper-plane"></i><span>&#8195;SEND</span> </Button>
                    </div>

                    <div style={{ marginTop: 15 }}>
                        <AutoCenter>
                            <Space style={{marginTop:30}}>
                                <Button style={{ backgroundColor: '#33CC33', border: '1px solid #33CC33' }}  size='large' color='primary' onClick={() => sendBackPage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="fas fa-backward"></i> </Button>
                                <Button style={{ backgroundColor: '#FF3300', border: '1px solid #FF3300' }} size='large' color='primary' size='large' color='primary' onClick={() => sendHomePage()}> <i style={{fontSize:25, color:"#FFFFFF"}} className="fa fa-home"></i> </Button>
                            </Space>
                        </AutoCenter>
                    </div>

                </Content>
                <MateDiaryFooter />
            </Layout>
        </div>
    )

}
export default ContactPage;